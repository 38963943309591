import {OveritUzivateleFormData} from '@common/utils/form';
import axios from 'axios';
import {ACCESS_TOKEN_COOKIE_NAME} from '../../server/service/AuthService';

type OveritUzivateleData = {
    input: OveritUzivateleFormData;
    recaptchaVerificationCode?: string;
    anonymousUserId: string;
};

export const OveritUzivateleService = {
    verify: async (postData: OveritUzivateleData) => {
        const result = await axios.post(`${window.location.origin}/api/authenticateUzivatele`, postData);

        return result.data;
    },
};

/**
 * It returns the cookie value from the server request object
 * @param {any} req - The request object from the server.
 * @returns The cookie value from the server.
 */
export const getCookieFromServer = (req: any) => {
    return req?.cookies[ACCESS_TOKEN_COOKIE_NAME];
};
