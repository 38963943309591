import {Kontakt, ObchodniPartner} from '@eon.cz/apollo13-graphql-web';
import {Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export const NULL_KEY = 'null';

type Props = {
    eanOrEic: string;
    mappedContacts: {
        [key: string]: Kontakt[];
    };
    obchodniPartner?: ObchodniPartner;
};

export const KontaktSideText: React.FC<Props> = ({eanOrEic, obchodniPartner, mappedContacts}) => {
    const {nazevSpolecnosti, jmeno, prijmeni} = obchodniPartner ?? {};

    if (eanOrEic !== NULL_KEY) {
        return (
            <Typography variant="body1">
                <FormattedMessage id={mappedContacts[eanOrEic][0]?.ean ? 'ean' : 'eic'} />
                <FormattedMessage id="symbols.semicolon" /> {eanOrEic}
            </Typography>
        );
    }

    if (nazevSpolecnosti) {
        return <Typography variant="body1">{nazevSpolecnosti}</Typography>;
    }

    if (jmeno && prijmeni) {
        return <Typography variant="body1">{jmeno + ' ' + prijmeni}</Typography>;
    }

    return null;
};
