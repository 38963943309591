import {Adresa} from '@eon.cz/apollo13-graphql-web';

export const AddressFormatUtils = {
    /**
     * Format street line from the address
     *
     * @param {Adresa} adresa Adress
     */
    formatStreetLine: (adresa: Adresa | null | undefined) => {
        let res = adresa?.ulice ?? '';

        if (adresa?.cisloOrientacni) {
            res += ` ${adresa?.cisloPopisne}/${adresa?.cisloOrientacni}`;
        }
        if (adresa?.cisloPopisne) {
            res += ` ${adresa?.cisloPopisne}`;
        }

        return res;
    },

    formatAddressLine: (adresa: Adresa | null | undefined) => (
        <>
            {AddressFormatUtils.formatStreetLine(adresa)} {adresa && 'mesto' in adresa && adresa.mesto} {adresa?.mistniCast} {adresa?.psc}
        </>
    ),
};

export const remapAddress = (adresa: Adresa | null | undefined): Adresa => ({
    cisloOrientacni: adresa?.cisloOrientacni ?? '',
    cisloPopisne: adresa?.cisloPopisne ?? '',
    mesto: adresa?.mesto ?? '',
    mistniCast: adresa?.mistniCast ?? '',
    psc: adresa?.psc ?? '',
    ulice: adresa?.ulice ?? '',
});
