import {HelpOutline} from '@mui/icons-material';
import {Tooltip, TooltipProps, Typography, styled, tooltipClasses} from '@mui/material';
import {FC, ReactNode, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Span} from '../../styledComponents/Span';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
    },
}));

type Props = Omit<TooltipProps, 'title' | 'children'> & {
    readonly text?: string | null;
    readonly textRaw?: string | null;
    readonly value?: any;
    readonly placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    readonly color?: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error';
    readonly fontSize?: 'inherit' | 'small' | 'large' | 'medium';
    readonly htmlText?: ReactNode;
    readonly animate?: boolean;
    readonly children?: ReactNode;
    readonly showIcon?: boolean;
    readonly withButton?: boolean;
    readonly inline?: boolean;
    readonly isInTable?: boolean;
    readonly open?: boolean;
};
export const TooltipIcon: FC<Props> = ({
    text,
    placement = 'top-start',
    color = 'inherit',
    fontSize,
    htmlText,
    animate,
    value,
    children,
    showIcon = true,
    textRaw,
    withButton,
    inline,
    open = true,
}) => {
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const handleOpenNapoveda = () => setTooltipIsOpen(true);
    const handleCloseNapoveda = () => setTooltipIsOpen(false);
    return (
        <CustomWidthTooltip
            open={tooltipIsOpen}
            onOpen={handleOpenNapoveda}
            onClose={handleCloseNapoveda}
            placement={placement}
            title={
                htmlText || text ? (
                    <Typography color="textPrimary" variant="body2" component="div">
                        {htmlText ? htmlText : <FormattedMessage id={text ?? 'empty'} values={value} />}
                    </Typography>
                ) : (
                    textRaw ?? ''
                )
            }
        >
            <Span sx={{display: 'flex'}}>
                {children}
                {(htmlText || text || textRaw) && showIcon && (
                    <HelpOutline
                        sx={(theme) => ({
                            opacity: 0.6,
                            fontSize: '0.9rem',
                            marginLeft: withButton ? -1 : 1,
                            color: 'secondary.main',
                            ...(open &&
                                animate && {
                                    borderRadius: '50%',
                                    transform: 'scale(1)',
                                    animation: 'pulse 2s infinite',
                                    '@keyframes pulse': {
                                        '0%': {
                                            transform: 'scale(0.95)',
                                            boxShadow: `0 0 0 0 ${theme.palette.secondary.main}`,
                                        },
                                        '70%': {
                                            transform: 'scale(1)',
                                            boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                                        },
                                        '100%': {
                                            transform: 'scale(0.95)',
                                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                        },
                                    },
                                }),
                            ...(inline && {
                                opacity: 0.6,
                                fontSize: '1rem',
                                marginBottom: `${theme.spacing(0)} !important`,
                                marginLeft: theme.spacing(1),
                            }),
                        })}
                        color={color}
                        fontSize={fontSize}
                        onClick={handleOpenNapoveda}
                    />
                )}
            </Span>
        </CustomWidthTooltip>
    );
};
