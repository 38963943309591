import {Adresa, SmlouvaOdbernehoMistaTypMereni} from '@eon.cz/apollo13-graphql-web';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import {ComponentType, ReactNode} from 'react';
import {Controller, FieldError, FieldPath, FieldValues, RegisterOptions, useFormContext} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {useMatches} from '../../utils/CommonUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../error/ErrorFocus';
import {DatePickerField} from './inputs/DatePickerInput';
import {MobileDatePickerField} from './inputs/MobileDatePicker';
import {TooltipIcon} from './tooltips/TooltipIcon';

type SelectData = {value: string | number; label: string};

type Props = {
    readonly label?: string;
    readonly labelRawText?: string;
    readonly helperText?: string;
    readonly defaultValue?: any;
    readonly required?: boolean;
    readonly autofocus?: boolean;
    readonly error?: FieldError;
    readonly startAdornment?: ReactNode;
    readonly endAdornment?: ReactNode;
    readonly passwordStrength?: ReactNode;
    readonly disabled?: boolean;
    readonly selectData?: SelectData[];
    readonly tooltip?: string | null;
    readonly tooltipValue?: any;
    readonly name: string;
    readonly CustomComponent?: ComponentType<any>;
    readonly selectedItem?: any;
    readonly customSelectComponent?: ReactNode;
    readonly autocomplete?: 'given-name' | 'family-name' | 'email' | 'tel' | 'street-address' | 'postal-code' | 'country' | 'organization' | 'off';
    readonly customChange?: (event: any) => void;
    readonly onOpen?: (event: any) => void;
    readonly isObec?: boolean;
    readonly placeholder?: string;
    readonly trimSpace?: boolean;
    readonly passwordHint?: string;
    // for OdberneMistoElektrinaAutocomplete
    readonly disableClear?: boolean;
    readonly ean?: boolean;
    readonly eic?: boolean;
    readonly vybranaAdresa?: boolean;
    readonly adresaObchodnihoPartnera?: Adresa | null;
    readonly children?: ReactNode;
    readonly multiline?: boolean;
    readonly initialValue?: string | number;
    readonly typMereni?: SmlouvaOdbernehoMistaTypMereni;
    readonly titleValue?: any;
    readonly mobileNumberOnly?: boolean;
    readonly loading?: boolean;
};

type BaseProps =
    | ({
          readonly type: 'text' | 'select' | 'number' | 'checkbox' | 'custom' | 'password' | 'radio' | 'radioGroup' | 'tel';
      } & Props)
    | ({
          readonly type: 'date' | 'dateRange';
      } & DateProps &
          Props);

type DateProps = {
    readonly minDate?: Date | string;
    readonly maxDate?: Date | string;
    readonly disableFuture?: boolean;
    readonly disablePast?: boolean;
    readonly showTodayButton?: boolean;
    readonly datumDo?: string | null;
    readonly datumOd?: string | null;
    readonly views?: Array<'day' | 'month' | 'year'>;
    readonly keyboard?: boolean;
    readonly shouldDisableDate?: boolean;
    readonly firstOfMonth?: boolean;
    readonly lastOfMonth?: boolean;
    readonly type?: 'date';
    readonly name: FieldPath<FieldValues>;
};

export const FormInput = (props: BaseProps) => {
    const {
        type,
        required = false,
        disabled = false,
        name,
        label,
        startAdornment,
        tooltip,
        autocomplete,
        autofocus,
        defaultValue,
        customChange,
        minDate,
        maxDate,
        disableFuture,
        disablePast,
        multiline,
        placeholder,
        trimSpace,
        endAdornment,
        passwordStrength,
        passwordHint,
        views,
        shouldDisableDate,
        firstOfMonth,
        lastOfMonth,
        tooltipValue,
        titleValue,
        children,
        helperText,
        selectData,
        customSelectComponent,
    } = props as BaseProps & DateProps;
    const matches = useMatches();

    const {formatMessage} = useIntl();
    const {control} = useFormContext();
    // React Hook Form validations

    if (type === 'date') {
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => {
                    return (
                        <>
                            {!matches && (
                                <DatePickerField
                                    name={name}
                                    value={value ?? ''}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    error={error}
                                    label={label}
                                    helperText={error ? error.message : null}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    disableFuture={disableFuture}
                                    disablePast={disablePast}
                                    data-testid={`form-field-${name}`}
                                    views={views}
                                    required={required}
                                />
                            )}
                            {matches && (
                                <MobileDatePickerField
                                    name={name}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    label={formatMessage({id: label})}
                                    error={!!error?.message}
                                    helperText={error ? error.message : null}
                                    required={required}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    disableFuture={disableFuture}
                                    disablePast={disablePast}
                                    data-testid={`form-field-${name}`}
                                    shouldDisableDate={shouldDisableDate}
                                    firstOfMonth={firstOfMonth}
                                    lastOfMonth={lastOfMonth}
                                    tooltip={
                                        <TooltipIcon
                                            text={tooltip}
                                            value={tooltipValue}
                                            placement="right-start"
                                            color={error ? 'error' : 'primary'}
                                            fontSize="small"
                                            showIcon
                                        />
                                    }
                                />
                            )}
                        </>
                    );
                }}
            />
        );
    }

    if (type === 'select') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => {
                    const handleOnChange = (event: SelectChangeEvent<any>) => {
                        customChange?.(event.target.value);
                        onChange(event);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <InputLabel sx={{marginLeft: -1.8, mt: 0.5}}>
                                <Typography component="span" sx={{color: disabled ? 'text.disabled' : 'inherit'}}>
                                    <Box component="span" sx={{fontWeight: 'bold'}}>
                                        {`${formatMessage({id: label})}${required ? ' *' : ''}`}
                                    </Box>
                                </Typography>
                            </InputLabel>
                            <Select
                                onBlur={onBlur}
                                value={value ?? ''}
                                onChange={handleOnChange}
                                required
                                disabled={disabled}
                                autoComplete={autocomplete}
                                input={
                                    <Input
                                        startAdornment={startAdornment}
                                        endAdornment={
                                            tooltip ? (
                                                <TooltipIcon
                                                    textRaw={typeof tooltip === 'string' ? tooltip : undefined}
                                                    htmlText={typeof tooltip === 'string' ? undefined : tooltip}
                                                    placement="right-start"
                                                    color="primary"
                                                    fontSize="small"
                                                    showIcon
                                                    inline
                                                />
                                            ) : undefined
                                        }
                                    />
                                }
                                sx={{
                                    '& .MuiSelect-icon': {
                                        color: 'primary.main',
                                        marginRight: tooltip ? 15 : undefined,
                                    },
                                }}
                                data-testid={`form-field-${name}`}
                            >
                                {!customSelectComponent &&
                                    selectData?.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            <FormattedMessage id={item.label} />
                                        </MenuItem>
                                    ))}
                                {children}
                            </Select>
                            {helperText && <FormHelperText style={{marginLeft: '0px'}}>{helperText}</FormHelperText>}
                            {!!error && <FormHelperText style={{marginLeft: '0px'}}>{error.message}</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        );
    }

    if (type === 'text' || type === 'number' || type === 'password' || type === 'tel') {
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({field, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        const trimmedValue = trimSpace ? event.target.value.trim() : event.target.value;
                        customChange?.(trimmedValue);
                        field.onChange(trimmedValue);
                    };
                    return (
                        <TextField
                            {...field}
                            type={type === 'password' ? 'password' : type === 'tel' ? 'tel' : 'text'}
                            id={field.name}
                            autoFocus={autofocus}
                            value={field.value ?? ''}
                            onChange={handleOnChange}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            multiline={multiline}
                            maxRows={10}
                            className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
                            data-testid={`form-field-${name}`}
                            placeholder={placeholder}
                            InputProps={{
                                startAdornment: startAdornment ? (
                                    <InputAdornment position="start" style={{marginBottom: 3}}>
                                        {startAdornment}
                                    </InputAdornment>
                                ) : undefined,
                                endAdornment: tooltip ? (
                                    <TooltipIcon text={tooltip} value={tooltipValue} placement="top" color="primary" fontSize="small" showIcon />
                                ) : endAdornment ? (
                                    endAdornment
                                ) : undefined,
                            }}
                            inputProps={
                                type === 'number'
                                    ? {
                                          inputMode: 'numeric',
                                          pattern: '/^-?d+(?:.d+)?$/g',
                                      }
                                    : undefined
                            }
                            label={
                                <Typography component="span">
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {formatMessage({id: label})}
                                    </Box>
                                </Typography>
                            }
                            fullWidth
                            variant="standard"
                            margin="dense"
                            error={!!error || !!passwordHint}
                            helperText={
                                error?.message ?? (
                                    <>
                                        <Typography variant="caption" color="textSecondary">
                                            {passwordHint}
                                        </Typography>
                                        {passwordStrength}
                                    </>
                                )
                            }
                            required={required}
                        />
                    );
                }}
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={name}
                                        checked={value}
                                        onChange={handleOnChange}
                                        onBlur={onBlur}
                                        color="primary"
                                        disabled={disabled}
                                        // required={required}
                                        data-testid={`form-field-${name}`}
                                    />
                                }
                                label={
                                    <TooltipIcon text={tooltip} value={tooltipValue} placement="right-start" color="primary" fontSize="small" showIcon>
                                        <Typography component="span">
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {required ? (
                                                    <span>
                                                        {formatMessage({id: label}, titleValue)} <sup>&nbsp;*</sup>
                                                    </span>
                                                ) : (
                                                    formatMessage({id: label}, titleValue)
                                                )}
                                            </Box>
                                        </Typography>
                                    </TooltipIcon>
                                }
                            />
                            {!!error?.message && (
                                <Box sx={{ml: 0.5, mt: -1.5}}>
                                    <FormHelperText data-testid={`form-field-error-${name}`} error>
                                        {error.message}
                                    </FormHelperText>
                                </Box>
                            )}
                        </FormControl>
                    );
                }}
            />
        );
    }

    return null;
};

// Support all validations according to RHF
export const getRHFValidations = (obj: RegisterOptions, options: {disabled: boolean; required: boolean}): RegisterOptions => {
    let validations = {...obj};

    if (obj.required || options.required) {
        validations = {...validations, required: obj?.required ?? 'Toto pole je povinné'};
    }

    if (obj.disabled || options.disabled) {
        validations = {...validations, disabled: true};
    }

    return validations;
};
