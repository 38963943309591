import {useMutation} from '@apollo/client';
import {PaperBlock} from '@common/components/layout/PaperBlock';
import {NotificationType} from '@common/components/notifications/NotificationModel';
import {AddressFormatUtils} from '@common/utils/AddressFormatUtils';
import {Kontakt, KontaktKomunikaceDruh, KontaktyMutationValidateKontaktArgs, Mutation, UcetSkupina} from '@eon.cz/apollo13-graphql-web';
import {useAppContext} from '@lib/context/AppContext';
import {VALIDATE_KONTAKT_MUTATION} from '@lib/graphql/mutations';
import {ZISKAT_KONTAKTY_QUERY} from '@lib/graphql/queries';
import {Card, CardContent, CardHeader, Grid, SxProps, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {KontaktChip} from '../chip/KontaktChip';

type Props = {
    readonly readOnly?: boolean;
    readonly onDeleteItem: (kontakt: Kontakt) => void;
    readonly kontakty: Kontakt[];
    readonly ucetSkupina: UcetSkupina | undefined | null;
};

const styles: {[key: string]: SxProps} = {
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    flex: {
        flex: 1,
    },
};

export const KontaktyPanelNezvalidovane: React.FC<Props> = ({readOnly, onDeleteItem, kontakty, ucetSkupina}) => {
    const {addNotification} = useAppContext();

    const [validateKontakt, {loading}] = useMutation<Mutation, KontaktyMutationValidateKontaktArgs>(VALIDATE_KONTAKT_MUTATION, {
        refetchQueries: [{query: ZISKAT_KONTAKTY_QUERY}],
        onCompleted: () => {
            addNotification({type: NotificationType.INFO, text: <FormattedMessage id="kontakty.zvalidovan" />});
        },
        onError: (error) => {
            addNotification({type: NotificationType.ERROR, text: error.graphQLErrors[0].message});
        },
    });

    // Map to contact type
    const contactChips: {[key in KontaktKomunikaceDruh]: React.ReactNode[]} = {
        ['DAT']: [],
        ['INT']: [],
        ['LTR']: [],
        ['MOB']: [],
    };

    if (kontakty.length > 0) {
        kontakty
            .filter((kontakt) => !kontakt.zvalidovany)
            .forEach((kontakt) => {
                contactChips[kontakt.druhKomunikace].push(
                    <KontaktChip kontakt={kontakt} onDeleteClicked={onDeleteItem} key={kontakt.id} readOnly={readOnly ?? false} />,
                );
            });
    }

    const handleValidateMutation = (kontakt: Kontakt) => {
        validateKontakt({
            variables: {
                input: {
                    idKontaktu: kontakt.id,
                    typReferencnihoObjektu: kontakt.typReferencnihoObjektu,
                    identifikaceReferencnihoObjektu: kontakt.identifikaceReferencnihoObjektu,
                },
            },
        });
    };

    return (
        <Grid item xs={12}>
            <PaperBlock titleBlock={<FormattedMessage id="kontakty.k.zvalidovani" />}>
                <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={12}>
                        <Card sx={styles.card}>
                            <CardHeader title={<FormattedMessage id="kontakty.zvalidovat.title" />} titleTypographyProps={{variant: 'h6'}} />
                            <CardContent sx={styles.flex}>
                                {kontakty
                                    .filter((kontakt) => !kontakt.zvalidovany)
                                    .map((kontakt) => {
                                        const komoditaTitle = kontakt.ean ? 'kontakt.chip.ean' : 'kontakt.chip.eic';
                                        return (
                                            <Grid container spacing={2} marginY={0.2} key={kontakt.id}>
                                                <Grid item xs={12} sm={5}>
                                                    <Grid item xs={12}>
                                                        {kontakt.ean || kontakt.eic ? (
                                                            <Typography variant="body1">
                                                                <FormattedMessage id={komoditaTitle} />
                                                                <FormattedMessage id="symbols.semicolon" /> {kontakt.ean ?? kontakt.eic}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="body1">{ucetSkupina?.nazev}</Typography>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {kontakt.ean || kontakt.eic ? (
                                                            <Typography variant="caption">
                                                                {AddressFormatUtils.formatAddressLine(kontakt?.eanAdresa ?? kontakt?.eicAdresa)}
                                                            </Typography>
                                                        ) : (
                                                            <Typography variant="caption">
                                                                {AddressFormatUtils.formatAddressLine(ucetSkupina?.obchodniPartner?.adresa)}
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <KontaktChip
                                                        notValidated={true}
                                                        kontakt={kontakt}
                                                        onDeleteClicked={onDeleteItem}
                                                        readOnly={readOnly ?? false}
                                                        handleZvalidovat={handleValidateMutation}
                                                        disabled={loading}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </PaperBlock>
        </Grid>
    );
};
