import {Link, Paper, Typography} from '@mui/material';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Pre} from '../styledComponents/Pre';
import {Span} from '../styledComponents/Span';
import {NotificationModel} from './NotificationModel';

type Props = {
    readonly notification?: NotificationModel;
    readonly moreMessages: number;
};

export const NotificationsMessageComponent = ({notification, moreMessages}: Props) => {
    const [errorShown, setErrorShown] = useState(false);
    const handleShowError = () => setErrorShown(true);

    if (notification === undefined) {
        return <span />;
    }

    return (
        <>
            <Typography color="inherit" sx={{paddingRight: 4}}>
                {notification.text}
                {notification.errorObject && !errorShown && (
                    <span>
                        &nbsp;{' '}
                        <Link
                            onClick={handleShowError}
                            sx={{
                                opacity: 0.8,
                                fontWeight: 'lighter',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                            }}
                        >
                            <FormattedMessage id="(zdroj)" />
                        </Link>
                    </span>
                )}
                {moreMessages > 0 && (
                    <Span
                        sx={{
                            fontWeight: 'lighter',
                            fontStyle: 'italic',
                            opacity: 0.6,
                        }}
                    >
                        &nbsp;
                        <FormattedMessage
                            id="notifications.messages.count"
                            values={{
                                messagesCount: moreMessages,
                            }}
                        />
                    </Span>
                )}
            </Typography>
            {notification.errorObject && errorShown && (
                <Paper>
                    <Pre
                        sx={{
                            padding: 1,
                            color: 'text.primary',
                            overflow: 'auto',
                        }}
                    >
                        {JSON.stringify(notification.errorObject, null, 2)}
                    </Pre>
                </Paper>
            )}
        </>
    );
};
