import {TextField} from '@mui/material';
import AutocompleteMui, {AutocompleteRenderOptionState} from '@mui/material/Autocomplete';
import {HTMLAttributes, SyntheticEvent, useEffect, useState} from 'react';
import {FieldError} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../error/ErrorFocus';

type Props = {
    readonly onChange: (...event: any[]) => void;
    readonly label: string;
    readonly placeholder?: string;
    readonly disabled?: boolean;
    readonly value: any;
    readonly defaultValue?: string;
    readonly error: FieldError | undefined;
    readonly options: string[];
    readonly itemToString?: (item: string) => string;
    readonly menuItem?: (
        props: HTMLAttributes<HTMLLIElement>,
        option: string | undefined,
        {selected, inputValue}: AutocompleteRenderOptionState,
    ) => JSX.Element;
};

export const Autocomplete = ({onChange, label, placeholder, error, options, value}: Props) => {
    const [inputValue, setInputValue] = useState<string | undefined>(value);
    const {formatMessage} = useIntl();

    useEffect(() => {
        if (!value) {
            setInputValue(undefined);
        }
    }, [value]);

    const handleOnManualChange = (_: SyntheticEvent<Element, Event>, value: any) => {
        const valNotbeNull = value ?? undefined;
        // What user choose from the list to send to form
        setInputValue(valNotbeNull);
        onChange(valNotbeNull);
    };

    return (
        <AutocompleteMui
            fullWidth
            freeSolo
            value={inputValue}
            id="Nekolikaradkovy vyber"
            options={options}
            className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
            onChange={handleOnManualChange}
            size="small"
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    onChange={onChange}
                    margin={'dense'}
                    sx={{marginTop: 1.4}}
                    label={formatMessage({id: label})}
                    placeholder={placeholder ? formatMessage({id: placeholder}) : ''}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
        />
    );
};
