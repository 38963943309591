import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';

type Props = SvgIconProps;

export const GasIcon: React.FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path d="M 13.125 3.506 C 13.397 4.449 15.697 13.536 9.786 13.536 C 9.005 13.536 7.416 13.034 6.647 12.05 C 6.187 11.496 5.925 10.762 5.859 9.845 C 5.482 10.629 5.209 11.211 5.041 11.591 C 4.653 12.64 4.513 13.844 4.653 15.31 C 5.146 19.401 8.876 21.349 11.865 21.237 C 15.581 21.237 18.515 18.427 18.813 14.584 C 18.851 11.989 18.273 9.419 16.078 6.466 C 14.681 4.836 13.563 3.756 13.125 3.506 Z M 11.198 1.827 C 10.985 1.395 11.543 0.458 12.441 0.98 C 13.227 1.473 15.2 2.857 17.29 5.461 C 19.376 8.061 20.507 11.347 20.393 14.479 C 20.22 19.237 16.506 22.826 11.756 22.826 C 7.154 22.826 3.497 19.672 3.059 15.325 C 2.668 11.439 4.509 8.562 6.203 7.278 C 6.436 7.101 6.76 7.091 7.003 7.253 C 7.224 7.399 7.334 7.656 7.289 7.906 C 7.289 7.906 6.45 12.092 10.077 12.092 C 12.723 12.092 12.681 7.099 11.455 2.704 C 11.375 2.417 11.289 2.124 11.198 1.827 Z" />
    </SvgIcon>
);
