import {CreateButton} from '@common/components/buttons/CreateButton';
import React from 'react';

type Props = {
    isLoading: boolean;
    disabled: boolean;
};

export const VerifyButton: React.FC<Props> = ({isLoading, disabled}) => (
    <CreateButton caption="kontakty.verifyDialogButton" disabled={disabled} running={isLoading} variant="contained" type="submit" fullWidth />
);
