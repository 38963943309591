import {useMutation} from '@apollo/client';
import {CreateButton} from '@common/components/buttons/CreateButton';
import {DialogContentWithBackground} from '@common/components/dialogs/DialogContentWithBackground';
import {FormInput} from '@common/components/formInput/FormInput';
import {NotificationType} from '@common/components/notifications/NotificationModel';
import {toKomodita} from '@common/utils/komodita';
import {
    Adresa,
    AdresaInput,
    CreateKontaktInput,
    KontaktKomunikaceDruh,
    Mutation,
    NotifikaceKategorie,
    OdberneMistoElektrina,
    OdberneMistoPlyn,
} from '@eon.cz/apollo13-graphql-web';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppContext} from '@lib/context/AppContext';
import {CREATE_KONTAKT_MUTATION} from '@lib/graphql/mutations';
import {Dialog, DialogActions, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {DialogTitleWithClose} from '../../../common/components/dialogs/DialogTitleWithClose';
import {LoadingDialog} from '../../../common/components/dialogs/LoadingDialog';
import {onSubmitFailFocusFieldWithError} from '../../../common/components/error/ErrorFocus';
import {TransitionSlideUp} from '../../../common/components/transitions/TransitionSlideUp';
import {remapAddress} from '../../../common/utils/AddressFormatUtils';
import {useMatches} from '../../../common/utils/CommonUtils';
import {validationSchemaCreateKontakt} from '../../../lib/validations/validationSchemaCreateKontakt';
import {EmailAutocomplete} from '../autocomplete/EmailAutocomplete';
import {OdberneMistoElektrinaAutocomplete} from '../autocomplete/OdberneMistoElektrinaAutocomplete';
import {TelephoneAutocomplete} from '../autocomplete/TelephoneAutocomplete';
import {CreateKontaktLTR} from './CreateKontaktLTR';
export type KontaktniUdajeProNaseptavani = {
    [key in KontaktKomunikaceDruh]: string[];
};

export type AccountGroupAddContactDialogModel = {
    readonly druhKomunikace: KontaktKomunikaceDruh | undefined | '';
    readonly kontaktniUdaj: string | undefined;
    readonly elektrina: boolean;
    readonly plyn: boolean;
    readonly adresa: AdresaInput | undefined;
    readonly odberneMisto: OdberneMistoElektrina | OdberneMistoPlyn | undefined;
};

export type CreateKontaktFormProps = {
    readonly druhyKomunikace: KontaktKomunikaceDruh[];
    readonly kontaktniUdajeProNaseptavani: KontaktniUdajeProNaseptavani;
    readonly kategoryInWhichKontaktIsBeingCreated: NotifikaceKategorie;
    readonly disableCommodities?: boolean;
    readonly adresaObchodnihoPartnera: Adresa | undefined | null;
    readonly showEan?: boolean;
    readonly showEic?: boolean;
    readonly showCommodities?: boolean;
    readonly initialValues: Partial<AccountGroupAddContactDialogModel>;
    readonly open: boolean;
};

type Props = {
    kategoryInWhichKontaktIsBeingCreated: NotifikaceKategorie;
    toggleIsCreating: (isCreating: boolean) => void;
    refetchKontakty: () => void;
    onClose: () => void;
} & CreateKontaktFormProps;

export const CreateKontaktForm: React.FC<Props> = ({
    disableCommodities,
    showCommodities,
    refetchKontakty,
    toggleIsCreating,
    onClose,
    kontaktniUdajeProNaseptavani,
    druhyKomunikace,
    showEan,
    showEic,
    adresaObchodnihoPartnera,
    kategoryInWhichKontaktIsBeingCreated,
    initialValues,
    open,
}) => {
    const matches = useMatches();

    const {addNotification} = useAppContext();

    const [isSaving, setIsSaving] = useState(false);

    const [createKontaktMutation, {loading}] = useMutation<Mutation>(CREATE_KONTAKT_MUTATION, {
        onCompleted: () => {
            setIsSaving(false);

            toggleIsCreating(false);

            addNotification({text: <FormattedMessage id="kontakty.vytvoren" />});

            refetchKontakty();
        },
        onError: (error) => {
            setIsSaving(false);
            toggleIsCreating(false);
            addNotification({type: NotificationType.ERROR, text: (error as any).graphQLErrors[0].errors[0].message});
        },
    });

    const methods = useForm<AccountGroupAddContactDialogModel & {vsechnaOdbernaMista?: boolean}>({
        resolver: yupResolver(validationSchemaCreateKontakt(kategoryInWhichKontaktIsBeingCreated.povolitVyberKomodity)),
        mode: 'onChange',
        defaultValues: {
            odberneMisto: {
                adresa: initialValues.adresa,
            },
            vsechnaOdbernaMista: true,
            elektrina: initialValues.elektrina,
            plyn: initialValues.plyn,
        },
    });

    const {
        handleSubmit,
        watch,
        formState: {errors},
        setValue,
    } = methods;

    const onSubmit = ({
        druhKomunikace,
        kontaktniUdaj,
        elektrina,
        adresa,
        odberneMisto,
        vsechnaOdbernaMista,
    }: AccountGroupAddContactDialogModel & {vsechnaOdbernaMista?: boolean}) => {
        setIsSaving(true);

        const komodita = kategoryInWhichKontaktIsBeingCreated?.povolitVyberKomodity ? toKomodita(elektrina) : null;

        const mutationVariables: CreateKontaktInput = {
            kategorie: kategoryInWhichKontaktIsBeingCreated?.id,
            druhKomunikace: druhKomunikace as KontaktKomunikaceDruh,
            kontaktniUdaj,
            komodita,
            adresa: druhKomunikace === 'LTR' && Object.keys(adresa ?? {}).length > 0 ? remapAddress(adresa) : null,
            ean: vsechnaOdbernaMista !== true && odberneMisto && 'eanSpotrebni' in odberneMisto ? odberneMisto.eanSpotrebni : null,
            eic: vsechnaOdbernaMista !== true && odberneMisto && 'eic' in odberneMisto ? odberneMisto.eic : null,
        };

        createKontaktMutation({variables: {input: mutationVariables}});
    };

    const druhyKomunikaceSelectValues = druhyKomunikace.map((druh) => ({
        value: druh,
        label: `kontakt.${druh}` ?? 'Druh komunikace',
    }));

    const druhKomunikaceWatch = watch('druhKomunikace');
    const odberneMistoWatch = watch('odberneMisto');
    const vsechnaOdbernaMistaWatch = watch('vsechnaOdbernaMista');
    const disablePlyn = !kategoryInWhichKontaktIsBeingCreated?.plyn;
    const disableElektrina = !kategoryInWhichKontaktIsBeingCreated?.elektrina;

    useEffect(() => {
        if (odberneMistoWatch?.adresa) {
            const adresa = remapAddress(odberneMistoWatch?.adresa);
            setValue('adresa', adresa);
        }
    }, [odberneMistoWatch?.adresa, setValue]);

    if (loading) {
        return <LoadingDialog open />;
    }

    return (
        <Dialog open={open} maxWidth="md" fullWidth onClose={onClose} TransitionComponent={TransitionSlideUp}>
            <DialogTitleWithClose onClose={onClose} title="kontakty.pridat" />
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                    <DialogContentWithBackground background="paper">
                        <Grid container spacing={1}>
                            {showCommodities && (
                                <>
                                    <Grid item xs={6}>
                                        <FormInput
                                            name="elektrina"
                                            label="komodita.elektrina"
                                            type="checkbox"
                                            error={errors?.elektrina}
                                            disabled={disableCommodities || disableElektrina}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormInput
                                            name="plyn"
                                            label="komodita.plyn"
                                            type="checkbox"
                                            error={errors?.plyn}
                                            disabled={disableCommodities || disablePlyn}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <FormInput name="druhKomunikace" label="kontakty.druh.komunikace" type="select" selectData={druhyKomunikaceSelectValues} />
                            </Grid>

                            {(showEan || showEic) && (
                                <Grid item xs={12}>
                                    <FormInput type="checkbox" name="vsechnaOdbernaMista" label="kontakt.ean.vsechna.odberna.mista" />
                                </Grid>
                            )}

                            {(showEan || showEic) && vsechnaOdbernaMistaWatch !== true && (
                                <Grid item xs={12}>
                                    <OdberneMistoElektrinaAutocomplete
                                        ean={!!showEan}
                                        eic={!!showEic}
                                        adresaObchodnihoPartnera={adresaObchodnihoPartnera ?? null}
                                        name="odberneMisto"
                                        vybranaAdresa={druhKomunikaceWatch === 'LTR'}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                {druhKomunikaceWatch === 'INT' && (
                                    <EmailAutocomplete kontaktyProNaseptavani={kontaktniUdajeProNaseptavani.INT} name="kontaktniUdaj" />
                                )}

                                {druhKomunikaceWatch === 'MOB' && (
                                    <TelephoneAutocomplete kontaktyProNaseptavani={kontaktniUdajeProNaseptavani.MOB} name="kontaktniUdaj" defaultValue="+420" />
                                )}

                                {druhKomunikaceWatch === 'DAT' && (
                                    <Grid item xs={12}>
                                        <FormInput name="kontaktniUdaj" label="ucet.datova.schranka" type="text" required />
                                    </Grid>
                                )}

                                {druhKomunikaceWatch === 'LTR' && odberneMistoWatch?.adresa && <CreateKontaktLTR />}
                            </Grid>
                        </Grid>
                    </DialogContentWithBackground>

                    <DialogActions>
                        <CreateButton fullWidth={matches} variant="contained" type="submit" caption="button.pridat" running={isSaving} />
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};
