import {Kontakt} from '@eon.cz/apollo13-graphql-web';
import {KontaktniUdajeProNaseptavani} from '../forms/CreateKontaktForm';

export const initialKontaktyProNaseptavani = {
    DAT: [],
    MOB: [],
    INT: [],
    LTR: [],
};

export const transformKontaktyProNaseptavani = (kontakty: Kontakt[]) =>
    kontakty.reduce<KontaktniUdajeProNaseptavani>(
        (acc, curr) => ({...acc, [curr.druhKomunikace]: [...new Set([...acc[curr.druhKomunikace], curr.kontaktniUdaj])]}),
        initialKontaktyProNaseptavani,
    );
