import {Grid} from '@mui/material';
import React from 'react';

type Props = {
    readonly chips: Record<string, any[]>;
};
const druhyKomunikace = ['INT', 'MOB', 'DAT', 'LTR'] as const;
export type DruhyKomunikaceTyp = typeof druhyKomunikace;

export const KontaktyKategorieOstatni: React.FC<Props> = ({chips}) => {
    return (
        <>
            {druhyKomunikace.map(
                (druhKomunikace) =>
                    chips[druhKomunikace].length > 0 && (
                        <Grid key={druhKomunikace} container spacing={1}>
                            <Grid>{chips[druhKomunikace]}</Grid>
                        </Grid>
                    ),
            )}
        </>
    );
};
