import {AutocompleteQuery} from '@common/components/autocomplete/AutocompleteQuery';
import {AddressFormatUtils} from '@common/utils/AddressFormatUtils';
import {Adresa, Komodita, OdberneMistoElektrina, OdberneMistoPlyn, Query} from '@eon.cz/apollo13-graphql-web';
import {SMLOUVY_ODBERNYCH_MIST_QUERY} from '@lib/graphql/queries';
import {isEqual} from 'lodash';
import {HTMLAttributes} from 'react';
import {Controller, FieldValues, Path, useFormContext} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

type Props = {
    readonly name: Path<FieldValues>;
    readonly defaultValue?: any;
    readonly ean: boolean;
    readonly eic: boolean;
    readonly adresaObchodnihoPartnera: Adresa | null;
    readonly disabled?: boolean;
    readonly vybranaAdresa: boolean;
};

export const OdberneMistoElektrinaAutocomplete = (props: Props) => {
    const {adresaObchodnihoPartnera, ean, eic, name, defaultValue, disabled, vybranaAdresa} = props;
    const {control} = useFormContext();

    const itemToString = (item: (OdberneMistoElektrina | OdberneMistoPlyn) | undefined): string => {
        if (typeof item === 'object' && 'eanSpotrebni' in item) {
            return item?.eanSpotrebni ?? 'Obchodní partner';
        }
        if (typeof item === 'object' && 'eic' in item) {
            return item?.eic ?? 'Obchodní partner';
        }

        return '';
    };
    const inputToVars = {
        napetovaUroven: eic ? null : vybranaAdresa ? ['VN', 'VVN'] : ['NN', 'VN', 'VVN'],
        komodita: ean && !eic ? Komodita.ELEKTRINA : eic && !ean ? Komodita.PLYN : null,
    };
    const isOptionEqualToValue = (
        option: OdberneMistoElektrina | OdberneMistoPlyn | undefined,
        value: OdberneMistoElektrina | OdberneMistoPlyn | undefined,
    ): boolean => isEqual(option?.adresa, value?.adresa);
    const renderOption = (props: HTMLAttributes<HTMLLIElement>, misto: OdberneMistoElektrina | OdberneMistoPlyn | undefined) => {
        return (
            <li {...props}>
                <div>
                    <div>
                        <strong>
                            {(misto && 'eanSpotrebni' in misto ? misto?.eanSpotrebni : misto?.eic) ?? <FormattedMessage id="kontakt.obchodni.partner" />}
                        </strong>
                    </div>
                    <div>
                        {`${AddressFormatUtils.formatStreetLine(misto?.adresa)}, ${misto?.adresa?.psc} ${misto?.adresa?.mesto} ${misto?.adresa?.mistniCast}`}
                    </div>
                </div>
            </li>
        );
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <AutocompleteQuery<Query, OdberneMistoElektrina | OdberneMistoPlyn>
                    {...props}
                    onChange={onChange}
                    value={value}
                    error={error}
                    query={SMLOUVY_ODBERNYCH_MIST_QUERY}
                    label="kontakty.odbernemisto.elektrina"
                    itemToString={itemToString}
                    menuItem={renderOption}
                    dataToItems={dataToItems(vybranaAdresa ? adresaObchodnihoPartnera : null, ean, eic)}
                    disabled={disabled}
                    inputToVars={inputToVars}
                    isOptionEqualToValue={isOptionEqualToValue}
                />
            )}
        />
    );
};

const dataToItems = (adresaObchodnihoPartnera: Adresa | null, ean: boolean, eic: boolean) => (data?: Query) => {
    const queryData = data?.smlouvyOdbernychMist?.naseptavac?.edges;

    if (!queryData) {
        return [];
    }

    if (adresaObchodnihoPartnera) {
        const elektrinaField = {
            eanSpotrebni: '',
            adresa: adresaObchodnihoPartnera,
        } as OdberneMistoElektrina;
        const plynField = {
            eic: '',
            adresa: adresaObchodnihoPartnera,
        } as OdberneMistoPlyn;
        const obchodniPartner = ean ? elektrinaField : plynField;
        // Pridame adresu obchodniho partnera, kdyz mame

        return queryData
            .map((edge) => {
                if (ean && eic) {
                    return {...edge?.node?.elektrina, ...edge?.node?.plyn};
                }
                if (ean) {
                    return edge?.node?.elektrina;
                }
                if (eic) {
                    return edge?.node?.plyn;
                }
            })
            .filter((v) => !!v)
            .concat(obchodniPartner);
    } else {
        return queryData
            ?.map((edge) => {
                if (ean && eic) {
                    return {...edge?.node?.elektrina, ...edge?.node?.plyn};
                }
                if (ean) {
                    return edge?.node?.elektrina;
                }
                if (eic) {
                    return edge?.node?.plyn;
                }
            })
            .filter((v) => !!v);
    }
};
