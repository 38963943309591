import {CloseOutlined} from '@mui/icons-material';
import {Box, IconButton, InputAdornment, Typography} from '@mui/material';
import {DateValidationError, MobileDatePicker} from '@mui/x-date-pickers';
import {endOfMonth, format, isSameDay, startOfMonth} from 'date-fns';
import {ReactNode, SyntheticEvent, useRef} from 'react';
import {FieldValues} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {isNullOrUndefinedOrEmpty} from '../../../utils/CommonUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../../error/ErrorFocus';
import {CalendarIcon} from '../../icons/CalendarIcon';
import {Span} from '../../styledComponents/Span';
import {TooltipIcon} from '../tooltips/TooltipIcon';

type Props = FieldValues & {
    readonly label?: string;
    readonly name: string;
    readonly title?: string;
    readonly autoFocus?: boolean;
    readonly helperText?: string | undefined | null;
    readonly FormHelperTextProps?: any;
    readonly disabled?: boolean;
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly onChange: (...event: any[]) => void;
    readonly onBlur: (...event: any[]) => void;
    readonly error: boolean;
    readonly required: boolean;
    readonly value: any;
    readonly disableFuture?: boolean;
    readonly disablePast?: boolean;
    readonly shouldDisableDate?: boolean;
    readonly firstOfMonth?: boolean;
    readonly lastOfMonth?: boolean;
    readonly tooltip?: ReactNode;
};

// Defaultní formát datumu
const DEFAULT_FORMAT = 'dd.MM.yyyy';

const renderFormLabel = (label: string | undefined, title: string, showRequired: boolean) => (
    <Span>
        {label}
        {showRequired ? <sup>*</sup> : false}
        {typeof title === 'string' && title.length > 0 && (
            <TooltipIcon value={title} placement="top">
                <sup>
                    &nbsp;&nbsp;
                    <FormattedMessage id="prilohy.otaznik" />
                </sup>
            </TooltipIcon>
        )}
    </Span>
);
export const MobileDatePickerField = ({
    onChange,
    onBlur,
    value,
    FormHelperTextProps,
    label,
    disabled,
    helperText,
    minDate,
    maxDate,
    error,
    required,
    name,
    disableFuture,
    tooltip,
    disablePast,
    shouldDisableDate,
    firstOfMonth,
    lastOfMonth,
}: Props) => {
    const divRef = useRef(null);
    const intl = useIntl();

    const helperTextId = helperText && name ? `${name}-helper-text` : undefined;
    const formattedLabel = label ? intl.formatMessage({id: label}) : undefined;

    /**
     * Handler na změnu datumu
     * @param {any} dateVal
     * @param {string|null} currentValue
     */
    const handleOnChange = (dateVal: Date | null, {validationError}: {validationError: DateValidationError}) => {
        if (validationError === 'invalidDate') {
            onBlur('');
            onChange('');
            return;
        }

        const date = dateVal ? format(new Date(dateVal as Date), 'yyyy-MM-dd') : null;
        onChange(date);
    };

    const handleClear = (e: SyntheticEvent) => {
        e?.stopPropagation();
        onChange(null);
    };

    const handleShouldDisableDate = (date: Date) => {
        if (shouldDisableDate) {
            if (firstOfMonth) {
                return isSameDay(startOfMonth(date as Date), date as Date) ? false : true;
            }
            if (lastOfMonth) {
                return isSameDay(endOfMonth(date as Date), date as Date) ? false : true;
            }
        }
        return false;
    };

    const labelText = renderFormLabel(formattedLabel, '', required);

    return (
        <MobileDatePicker
            ref={divRef}
            label={labelText}
            value={isNullOrUndefinedOrEmpty(value) ? null : new Date(value)}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={handleOnChange}
            format={DEFAULT_FORMAT}
            shouldDisableDate={handleShouldDisableDate}
            localeText={{
                clearButtonLabel: 'Smazat',
                todayButtonLabel: 'Dnes',
                toolbarTitle: 'Vyberte datum',
                cancelButtonLabel: 'Zrušit',
                okButtonLabel: 'Potvrdit',
            }}
            slotProps={{
                actionBar: {
                    actions: ['cancel', 'accept'],
                },
                textField: {
                    datatype: 'date-picker',
                    sx: {marginTop: 1},
                    className: error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined,
                    variant: 'standard',
                    autoComplete: 'date',
                    label: (
                        <Typography component="span">
                            <Box component="span" sx={{fontWeight: 'bold'}}>
                                {labelText}
                            </Box>
                        </Typography>
                    ),
                    fullWidth: true,
                    error,
                    helperText,
                    FormHelperTextProps: {id: helperTextId, ...FormHelperTextProps},
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <TooltipIcon htmlText={<FormattedMessage id="tooltip.smazat" />} aria-label="delete" placement="top-start" showIcon={false}>
                                    <Span>
                                        <IconButton
                                            onClick={handleClear}
                                            size="small"
                                            sx={{
                                                width: 28,
                                                height: 28,
                                            }}
                                            disabled={disabled}
                                            data-testid={`${name}-calendar-delete-button`}
                                        >
                                            <CloseOutlined
                                                color={error ? 'error' : 'secondary'}
                                                sx={{
                                                    opacity: disabled ? 0.2 : 0.6,
                                                }}
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </Span>
                                </TooltipIcon>

                                <TooltipIcon
                                    htmlText={<FormattedMessage id="tooltip.kalendar" />}
                                    aria-label="calendar-pick"
                                    placement="top-start"
                                    showIcon={false}
                                >
                                    <Span>
                                        <IconButton
                                            onClick={undefined}
                                            size="small"
                                            sx={{
                                                width: 28,
                                                height: 28,
                                            }}
                                            disabled={disabled}
                                            data-testid={`${name}-calendar-open-button`}
                                        >
                                            <CalendarIcon
                                                color={error ? 'error' : 'secondary'}
                                                sx={{
                                                    opacity: disabled ? 0.2 : 0.6,
                                                }}
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </Span>
                                </TooltipIcon>

                                {tooltip}
                            </InputAdornment>
                        ),
                    },
                },
            }}
        />
    );
};
