import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';

type Props = SvgIconProps;

export const CheckIcon: React.FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path
            d="M37.328,15.573a1.107,1.107,0,0,0-1.107-1.107,1.09,1.09,0,0,0-.831.4l-.01-.009L19.509,33.4l-4.692-4.256-1.488,1.642,6.382,5.787L37.062,16.293l-.01-.009a1.091,1.091,0,0,0,.276-.711Z"
            transform="translate(-13.328 -14.466)"
        />
    </SvgIcon>
);
