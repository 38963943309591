import {gql} from '@apollo/client';

export const DELETE_KONTAKT_MUTATION = gql`
    mutation DeleteKontakt($id: ID!) {
        kontakty {
            deleteKontakt(id: $id) {
                id
            }
        }
    }
`;
