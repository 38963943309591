import {KontaktKomunikaceDruh} from '@eon.cz/apollo13-graphql-web';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';

// Common setup validations
yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
        email: () => <FormattedMessage id="yup.email.format" />,
    },
});

const string = yup.string();

// GENVAL005
// GENVAL009
const email = string
    .required()
    .email()
    .min(6)
    .max(254)
    .matches(
        /^[\w](?!.*([.])\1{1})(?:[a-zA-Z0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*[a-zA-Zá-žÁ-Ž0-9]*[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]){0,1}\@[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*){0,5}\.[a-zA-Z0-9]+(?:[-a-zA-Z0-9]+)*[a-zA-Z0-9]$/,
        () => <FormattedMessage id="yup.email.format" />,
    );

// GENVAL054
const datovaSchranka = string.required().min(7).max(10);

const telefonCZ = string.matches(/^(\+420|00420)??[6-7][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.cz" />);
const telefonSK = string.matches(/^(\+421|00421)??[9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.sk" />);

const validateTelefon = (telefon: string) => {
    if (telefon?.length === 0) {
        return string.required();
    }
    if (/00420|\+420/.test(telefon)) {
        return telefonCZ;
    }
    if (/00421|\+421/.test(telefon)) {
        return telefonSK;
    }
    if (/^\d+$/.test(telefon)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.prefix" />,
            () => {
                return false;
            },
        );
    }

    if (!/^\d+$/.test(telefon)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    return string.required();
};

const kontaktniUdajOption = (druh: KontaktKomunikaceDruh, value: string) => {
    console.log('🚀 ~ kontaktniUdajOption ~ druh:', druh);
    console.log('🚀 ~ kontaktniUdajOption ~ value:', value);
    if (druh === KontaktKomunikaceDruh.MOB) {
        return validateTelefon(value);
    }
    if (druh === KontaktKomunikaceDruh.DAT) {
        return datovaSchranka;
    }
    if (druh === KontaktKomunikaceDruh.INT) {
        return email;
    }
    return string.nullable();
};

export const validationSchemaCreateKontakt = (povolitVyberKomodity: boolean) =>
    yup.object().shape({
        druhKomunikace: string.required(),
        kontaktniUdaj: string.nullable().when('druhKomunikace', (druhKomunikace: any) => yup.lazy((value) => kontaktniUdajOption(druhKomunikace[0], value))),
        adresa: yup
            .object()
            .nullable()
            .when('druhKomunikace', {
                is: (val: KontaktKomunikaceDruh) => {
                    return val === KontaktKomunikaceDruh.LTR;
                },
                then: () =>
                    yup
                        .object()
                        .shape({
                            cisloOrientacni: string.nullable().max(10),
                            cisloPopisne: string.nullable().max(10).required(),
                            mesto: string.max(70).required(),
                            mistniCast: string.max(40),
                            psc: string.min(5).max(10).required(),
                            ulice: string.max(60).required(),
                        })
                        .required(),
            }),
        elektrina: yup.boolean().test(
            '',
            () => <FormattedMessage id="yup.required" />,
            (value, ctx) => {
                return !povolitVyberKomodity || value || ctx.parent.plyn;
            },
        ),
        plyn: yup.boolean().test(
            '',
            () => <FormattedMessage id="yup.required" />,
            (value, ctx) => {
                return !povolitVyberKomodity || value || ctx.parent.elektrina;
            },
        ),
    });
