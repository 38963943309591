import {OperationVariables, useQuery} from '@apollo/client';
import {Box, Typography} from '@mui/material';
import AutocompleteMui, {AutocompleteRenderOptionState} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {DocumentNode} from 'graphql';
import {debounce} from 'lodash';
import {HTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {FieldError} from 'react-hook-form';
import {useIntl} from 'react-intl';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../error/ErrorFocus';
type Props<T, U> = {
    readonly name: string;
    readonly query: DocumentNode;
    readonly fetchPolicy?: 'cache-and-network' | 'cache-first' | 'network-only' | 'no-cache' | 'cache-only';
    readonly onChange: (...event: Record<string, any>[]) => void;
    readonly label: string;
    readonly placeholder?: string;
    readonly disabled?: boolean;
    readonly value: any;
    readonly defaultValue?: any;
    readonly error: FieldError | undefined;
    readonly dataToItems: (data: T | undefined) => any[];
    readonly itemToString: (item: U | undefined) => string;
    readonly menuItem: (props: HTMLAttributes<HTMLLIElement>, option: U | undefined, {selected, inputValue}: AutocompleteRenderOptionState) => JSX.Element;
    readonly keyValue?: {type?: 'object'; key: string};
    readonly inputToVars?: {[key: string]: any};
    readonly isOptionEqualToValue?: (option: U | undefined, value: U | undefined) => boolean;
    readonly required?: boolean;
};

export const AutocompleteQuery = <T, U>({
    query,
    fetchPolicy,
    onChange,
    label,
    placeholder,
    error,
    value,
    dataToItems,
    itemToString,
    menuItem,
    defaultValue,
    disabled,
    inputToVars,
    isOptionEqualToValue,
    name,
    required,
}: Props<T, U>) => {
    const [variables, setVariables] = useState<OperationVariables | undefined>({inputValue: '', ...inputToVars});
    const [inputValue, setInputValue] = useState<U | undefined>(value);
    const [open, setOpen] = useState(false);
    const {formatMessage} = useIntl();

    useEffect(() => {
        if (!value) {
            setInputValue(undefined);
        }
    }, [value]);

    const {loading, data} = useQuery<T>(query, {
        fetchPolicy: fetchPolicy ?? 'no-cache',
        variables,
        skip: !open,
    });

    const handleOnChange = useCallback(
        (_: SyntheticEvent<Element, Event>, value: string) => {
            setVariables({inputValue: value, ...inputToVars});
        },
        [inputToVars],
    );

    const handleOnManualChange = (_: SyntheticEvent<Element, Event>, value: any) => {
        const valNotbeNull = value ?? undefined;
        // What user choose from the list to send to form
        setInputValue(valNotbeNull);
        onChange(valNotbeNull);
    };
    // A debounce function. It is used to prevent the function from being called too often.
    const debouncedResults = useMemo(() => {
        return debounce(handleOnChange, 500);
    }, [handleOnChange]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <AutocompleteMui
            loading={loading}
            value={inputValue}
            id="Nekolikaradkovy vyber"
            isOptionEqualToValue={isOptionEqualToValue}
            options={dataToItems(data)}
            onOpen={handleOpen}
            onClose={handleClose}
            renderOption={menuItem}
            onChange={handleOnManualChange}
            fullWidth
            onInputChange={debouncedResults}
            getOptionLabel={itemToString}
            defaultValue={defaultValue}
            disabled={disabled}
            className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
            size="small"
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        name={name}
                        variant="standard"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        fullWidth
                        margin={'dense'}
                        sx={{marginTop: 1.4}}
                        label={
                            <Typography component="span">
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {formatMessage({id: label})}
                                </Box>
                            </Typography>
                        }
                        placeholder={placeholder ? formatMessage({id: placeholder}) : ''}
                        error={!!error}
                        helperText={error?.message}
                        required={required}
                        data-testid={`form-field-${name}`}
                    />
                );
            }}
        />
    );
};
