import {VerifyButton} from '@common/components/buttons/VerifyButton';
import {LoadingDialog} from '@common/components/dialogs/LoadingDialog';
import {onSubmitFailFocusFieldWithError} from '@common/components/error/ErrorFocus';
import {FormInput} from '@common/components/formInput/FormInput';
import {NotificationType} from '@common/components/notifications/NotificationModel';
import {recaptchaSiteKey} from '@common/constants';
import {isNotNullOrUndefinedOrEmpty} from '@common/utils/CommonUtils';
import {OveritUzivateleFormData} from '@common/utils/form';
import {ObchodniPartnerTyp, PredvyplneneUdaje, PredvyplneneUdajeEan, PredvyplneneUdajeEic} from '@eon.cz/apollo13-graphql-web';
import {yupResolver} from '@hookform/resolvers/yup';
import {generateAnonymousUserId} from '@lib/auth/getAnonymousUserId';
import {useAppContext} from '@lib/context/AppContext';
import {validationSchemaOveritUzivatele} from '@lib/validations/validationSchemaOveritUzivatele';
import {Grid, Typography} from '@mui/material';
import {startOfDay, subYears} from 'date-fns';
import {useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {SAP_ERROR_TYPE} from '../../../../server/service/Constants';
import {OveritUzivateleService} from '../../../service/OveritUzivateleService';

type Props = {
    closeDialog: () => void;
    toggleIsAuthenticated: (value: boolean) => void;
    predvyplneneUdaje?: PredvyplneneUdaje;
};

const typSubjektuSelectOptions = [
    {
        value: ObchodniPartnerTyp.FYZICKA_OSOBA,
        label: 'kontakty.labels.fyzickaOsoba',
    },
    {
        value: ObchodniPartnerTyp.PRAVNICKA_OSOBA,
        label: 'kontakty.labels.pravnickaOsoba',
    },
];

const defaultValues: OveritUzivateleFormData = {
    typSubjektu: '',
    cestneProhlaseni: false,
    cisloElektromeru: '',
    cisloPlynomeru: '',
    datumNarozeni: '',
    ic: '',
    ean: '859182400',
    eic: '27ZG900',
};

export const OveritUzivateleForm: React.FC<Props> = ({closeDialog, toggleIsAuthenticated, predvyplneneUdaje}) => {
    const {addNotification} = useAppContext();

    const [recaptchaCode, setRecaptchaCode] = useState<string | null>('');
    const [isRecaptchaRequired, setIsRecaptchaRequired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<OveritUzivateleFormData>({
        resolver: yupResolver(validationSchemaOveritUzivatele, {}),
        mode: 'onChange',
        defaultValues: {
            ...defaultValues,
            ean: (predvyplneneUdaje as PredvyplneneUdajeEan)?.ean ?? defaultValues.ean,
            eic: (predvyplneneUdaje as PredvyplneneUdajeEic)?.eic ?? defaultValues.eic,
            cisloElektromeru: (predvyplneneUdaje as PredvyplneneUdajeEan)?.ean
                ? (predvyplneneUdaje as PredvyplneneUdajeEan)?.cislo
                : ('' ?? defaultValues.cisloElektromeru),
            cisloPlynomeru: (predvyplneneUdaje as PredvyplneneUdajeEic)?.eic
                ? (predvyplneneUdaje as PredvyplneneUdajeEic)?.cislo
                : ('' ?? defaultValues.cisloPlynomeru),
        },
    });

    const {
        handleSubmit,
        watch,
        formState: {errors},
        control,
    } = methods;

    const eic = useWatch({
        control,
        name: 'eic',
    });
    const ean = useWatch({
        control,
        name: 'ean',
    });
    const cisloElektromeru = useWatch({
        control,
        name: 'cisloElektromeru',
    });
    const cisloPlynomeru = useWatch({
        control,
        name: 'cisloPlynomeru',
    });

    const handleRecaptchaRequired = (recaptchaRequired: boolean) => {
        setIsRecaptchaRequired(recaptchaRequired);
    };

    const onSubmit = async (formData: OveritUzivateleFormData) => {
        setIsLoading(true);
        const input = {
            ...formData,
            ean: elVyplnena ? formData.ean : undefined,
            eic: plynVyplnen ? formData.eic : undefined,
        };

        const anonymousUserId = generateAnonymousUserId();

        const sendData = {
            input,
            anonymousUserId,
        };

        try {
            const overitUzivateleResult = await OveritUzivateleService.verify(
                isRecaptchaRequired && recaptchaCode ? {...sendData, recaptchaVerificationCode: recaptchaCode} : sendData,
            );

            setIsLoading(false);

            if (overitUzivateleResult.isSuccessfullyAuthenticated) {
                toggleIsAuthenticated(true);

                closeDialog();
            } else {
                addNotification({text: <FormattedMessage id="error.login" />, type: NotificationType.ERROR});
            }
        } catch (err: any) {
            setIsLoading(false);
            const recaptchaRequired = err?.response?.data?.isRecaptchaRequired ?? false;

            if (err.response.data.type === SAP_ERROR_TYPE) {
                addNotification({text: err.response.data.message, type: NotificationType.ERROR});
            } else {
                addNotification({text: <FormattedMessage id={recaptchaRequired ? 'error.login.recaptcha' : 'error.login'} />, type: NotificationType.ERROR});
                if (recaptchaRequired) {
                    handleRecaptchaRequired(true);
                }
            }
        }
    };

    const watchTypSubjektu = watch('typSubjektu');
    const confirmedCestneProhlaseni = watch('cestneProhlaseni') === true;

    const isSubmitButtonDisabled = isLoading || !confirmedCestneProhlaseni || !watchTypSubjektu;

    const elVyplnena = (ean !== '859182400' && isNotNullOrUndefinedOrEmpty(ean)) || isNotNullOrUndefinedOrEmpty(cisloElektromeru);
    const plynVyplnen = (eic !== '27ZG900' && isNotNullOrUndefinedOrEmpty(eic)) || isNotNullOrUndefinedOrEmpty(cisloPlynomeru);

    const handleRecaptchaChange = (code: string | null) => setRecaptchaCode(code);

    const isTo110yearsOld = startOfDay(subYears(new Date(), 110));
    const isMoreThen18yearsOld = subYears(new Date(), 18);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onSubmitFailFocusFieldWithError)} noValidate>
                {isLoading && <LoadingDialog open />}
                <Grid container spacing={2} paddingTop={1}>
                    {/* TYP SUBJEKTU  */}
                    <Grid item xs={12} md={6}>
                        <FormInput
                            type="select"
                            label="kontakty.labels.typSubjektu"
                            name="typSubjektu"
                            error={errors?.typSubjektu}
                            selectData={typSubjektuSelectOptions}
                            required
                        />
                    </Grid>

                    {/* DATUM NAROZENI / ICO  */}
                    <Grid item xs={12} md={6}>
                        {watchTypSubjektu === ObchodniPartnerTyp.FYZICKA_OSOBA && (
                            <FormInput
                                maxDate={isMoreThen18yearsOld}
                                minDate={isTo110yearsOld}
                                name="datumNarozeni"
                                type="date"
                                label={'kontakty.labels.datumNarozeni'}
                                required
                                disableFuture
                            />
                            // <DatePickerField
                            //     label="kontakty.labels.datumNarozeni"
                            //     name="datumNarozeni"
                            //     error={errors?.datumNarozeni}
                            //     minDate={isTo110yearsOld}
                            //     maxDate={isMoreThen18yearsOld}
                            //     required
                            //     disableFuture
                            // />
                        )}
                        {watchTypSubjektu === ObchodniPartnerTyp.PRAVNICKA_OSOBA && <FormInput type="number" label="kontakty.labels.ico" name="ic" required />}
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography variant="body1" paddingTop={2}>
                            <FormattedMessage id="kontakty.ean.eic.info" />
                        </Typography>
                    </Grid>

                    {/* Ean */}
                    <Grid item xs={12} md={6}>
                        <FormInput type="number" label="kontakty.labels.ean" name="ean" error={errors?.ean} disabled={plynVyplnen} tooltip="tooltip.ean" />
                    </Grid>

                    {/* Číslo elektroměru */}
                    <Grid item xs={12} md={6}>
                        <FormInput
                            type="number"
                            label="kontakty.labels.cisloElektromeru"
                            name="cisloElektromeru"
                            error={errors?.cisloElektromeru}
                            disabled={plynVyplnen}
                            tooltip="tooltip.elektromer"
                        />
                    </Grid>

                    {/* Eic */}
                    <Grid item xs={12} md={6}>
                        <FormInput type="text" label="kontakty.labels.eic" name="eic" error={errors?.eic} tooltip="tooltip.eic" disabled={elVyplnena} />
                    </Grid>

                    {/* Číslo plynoměru */}
                    <Grid item xs={12} md={6}>
                        <FormInput
                            type="number"
                            label="kontakty.labels.cisloPlynomeru"
                            name="cisloPlynomeru"
                            error={errors?.cisloPlynomeru}
                            disabled={elVyplnena}
                            tooltip="tooltip.plynomer"
                        />
                    </Grid>

                    {/* SOUHLAS */}
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormInput
                                    type="checkbox"
                                    name="cestneProhlaseni"
                                    label="kontakty.labels.cestneProhlaseni"
                                    error={errors?.cestneProhlaseni}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {isRecaptchaRequired && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <ReCAPTCHA badge="bottomleft" size="normal" sitekey={recaptchaSiteKey as string} onChange={handleRecaptchaChange} />
                        </Grid>
                    )}

                    <Grid container item justifyContent="end" gap={2}>
                        <VerifyButton isLoading={isLoading} disabled={isSubmitButtonDisabled} />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
