import DeleteIcon from '@mui/icons-material/Delete';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@mui/material';
import {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {useMatches} from '../../utils/CommonUtils';
import {ActionButton} from '../buttons/ActionButton';

type ConfirmDialogProps = {
    /**
     * Whether is dialog open
     */
    readonly open: boolean;

    /**
     * Whether there is progress
     */
    readonly loading?: boolean;

    /**
     * Dialog title id
     */
    readonly title?: string;

    /**
     * Dialog description id or node
     */
    readonly description?: string | ReactNode;

    /**
     * "No" text for No button
     */
    readonly noButtonText?: string;

    /**
     * "Yes" text for Yes button
     */
    readonly yesButtonText?: string;

    /**
     * "No" handler
     */
    readonly onClickNo?: () => void;

    /**
     * "Yes" handler
     */
    readonly onClickYes?: () => void;
};

/**
 * Show confirm dialog
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({open, loading, title, description, noButtonText, yesButtonText, onClickNo, onClickYes}) => {
    const matches = useMatches();

    const handleOnClickYes = () => {
        if (onClickYes) {
            onClickYes();
        }
    };

    const handleOnClickNo = () => {
        if (onClickNo) {
            onClickNo();
        }
    };

    return (
        <Dialog open={open} keepMounted>
            <DialogTitle>
                <FormattedMessage id={title ?? 'potvrzeni'} />
            </DialogTitle>
            <DialogContent>
                {description && typeof description === 'string' ? (
                    <DialogContentText>
                        <FormattedMessage id={description} />
                    </DialogContentText>
                ) : (
                    description
                )}
            </DialogContent>
            <DialogActions>
                <Grid container spacing={1} sx={{textAlign: 'right'}}>
                    <Grid item xs={12} sm={10}>
                        <ActionButton
                            type="button"
                            sx={{height: '3rem'}}
                            onClick={handleOnClickYes}
                            variant="contained"
                            caption={yesButtonText ? yesButtonText : 'button.ano'}
                            icon={<DeleteIcon />}
                            // color="primary"
                            running={loading}
                            fullWidth={matches}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            sx={(theme) => ({
                                border: `solid 1px ${theme.palette.primary.main}`,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.main,
                                    color: theme.palette.common.white,
                                    border: `solid 1px ${theme.palette.secondary.main}`,
                                },
                            })}
                            size="small"
                            onClick={handleOnClickNo}
                            disabled={loading}
                            fullWidth={matches}
                        >
                            <FormattedMessage id={noButtonText ? noButtonText : 'button.ne'} />
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
