import {Close as CloseIcon} from '@mui/icons-material';
import {DialogTitle, DialogTitleProps, IconButton, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export type DialogTitleWithCloseProps = DialogTitleProps & {
    /**
     * ID of dialog title message
     */
    readonly title: string;

    /**
     * Dialog title color
     */
    readonly color?: 'primary' | 'secondary';

    /**
     * Disable close button
     */
    readonly disabledClose?: boolean;

    /**
     * Handled called when close is clicked
     */
    readonly onClose: () => void;
};

/**
 * Render dialog title when close button
 */
export const DialogTitleWithClose: React.FC<DialogTitleWithCloseProps> = ({title, onClose, disabledClose, ...props}) => {
    return (
        <DialogTitle variant="h6" sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} {...props}>
            <Typography variant="h6" component="span">
                <FormattedMessage id={title}>{(msg: any) => <div>{msg}</div>}</FormattedMessage>
            </Typography>

            <IconButton color="inherit" sx={{marginLeft: '8px'}} onClick={onClose} aria-label="Close" disabled={disabledClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};
