import {DialogContent} from '@mui/material';

export type DialogContentWithBackgroundProps = {
    /**
     * Which color to use for background
     */
    background: 'default' | 'paper';

    children: React.ReactNode;
};

/**
 * Produce DialogContent with background set to selected color from the theme
 */
export const DialogContentWithBackground: React.FC<DialogContentWithBackgroundProps> = ({background, children}) => {
    return (
        <DialogContent
            sx={{
                padding: '8px 24px 24px',
            }}
            className={background ? 'backgroudn' : ''}
        >
            {children}
        </DialogContent>
    );
};
