import {AddButton} from '@common/components/buttons/AddButton';
import {PaperBlock} from '@common/components/layout/PaperBlock';
import {Kontakt, KontaktKomunikaceDruh, NotifikaceKategorie, NotifikaceSkupina, UcetSkupina} from '@eon.cz/apollo13-graphql-web';
import {Card, CardActions, CardContent, CardHeader, Grid, SxProps, Typography} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {KontaktChip} from '../chip/KontaktChip';
import {KontaktyKategorieOstatni} from '../kategorie/KontaktyKategorieOstatni';
import {KontaktyKategorieWithEanOrEic} from '../kategorie/KontaktyKategorieWithEanOrEic';
import {KontaktyPanelNezvalidovane} from '../kontaktyPanelNezvalidovane/KontaktyPanelNezvalidovane';

type Props = {
    readonly skupinyNotifikaci: NotifikaceSkupina[];
    readonly onAddItem: (kategorie: NotifikaceKategorie) => void;
    readonly onDeleteItem: (kontakt: Kontakt) => void;
    readonly ucetSkupina: UcetSkupina | undefined | null;
    readonly kontakty: Kontakt[];
};

const styles: {[key: string]: SxProps} = {
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
    },
};

export const KontaktyPrehled: React.FC<Props> = ({kontakty, skupinyNotifikaci, ucetSkupina, onDeleteItem, onAddItem}) => {
    const kontaktyMapByKategorieId = kontakty.reduce<Record<string, Kontakt[]>>(
        (map, kontakt) => ({
            ...map,
            [kontakt.kategorie?.id ?? '']: [...(map[kontakt.kategorie?.id ?? ''] ?? []), kontakt],
        }),
        {},
    );

    return (
        <Grid container spacing={1}>
            {/* Nezvalidovane kontakty */}
            {kontakty.filter((kontakt) => !kontakt.zvalidovany).length > 0 && (
                <KontaktyPanelNezvalidovane onDeleteItem={onDeleteItem} kontakty={kontakty} ucetSkupina={ucetSkupina} />
            )}

            {skupinyNotifikaci.map((skupina) => (
                <Grid item xs={12} key={skupina?.id}>
                    <PaperBlock titleBlock={<span>{skupina?.nazev}</span>}>
                        <Grid container spacing={1}>
                            {skupina.kategorie.map((kategorie) => {
                                const contactChips: {[key in KontaktKomunikaceDruh]: React.ReactNode[]} = {
                                    DAT: [],
                                    INT: [],
                                    LTR: [],
                                    MOB: [],
                                };

                                if (kontaktyMapByKategorieId[kategorie.id]) {
                                    kontaktyMapByKategorieId[kategorie.id].forEach((kontakt) => {
                                        contactChips[kontakt.druhKomunikace].push(
                                            <KontaktChip kontakt={kontakt} key={kontakt.id} readOnly={false} onDeleteClicked={onDeleteItem} />,
                                        );
                                    });
                                }

                                const isAllowedEanOrEic = (kategorie.elektrina && kategorie.povolitVyberEAN) || (kategorie.plyn && kategorie.povolitVyberEIC);

                                return (
                                    <Grid item xs={12} md={6} lg={kategorie.kod === 'TECH_K_MKM' ? 12 : isAllowedEanOrEic ? 6 : 4} key={kategorie.id}>
                                        <Card sx={styles.card}>
                                            <CardHeader title={kategorie.nazev} titleTypographyProps={{variant: 'h6'}} />

                                            <CardContent sx={styles.cardContent}>
                                                {isAllowedEanOrEic ? (
                                                    <KontaktyKategorieWithEanOrEic
                                                        kontaktyMap={kontaktyMapByKategorieId}
                                                        kategorie={kategorie}
                                                        onDeleteItem={onDeleteItem}
                                                        ucetSkupina={ucetSkupina}
                                                    />
                                                ) : (
                                                    <KontaktyKategorieOstatni chips={contactChips} />
                                                )}
                                            </CardContent>

                                            <CardActions>
                                                <AddButton variant="contained" disabled={false} onClick={() => onAddItem(kategorie)} />
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </PaperBlock>
                </Grid>
            ))}

            {skupinyNotifikaci.length === 0 && (
                <Grid item xs={12}>
                    <PaperBlock titleBlock="kontakty.no.categories.title">
                        <Typography variant="body1">
                            <FormattedMessage id="kontakty.no.categories.content" />
                        </Typography>
                    </PaperBlock>
                </Grid>
            )}
        </Grid>
    );
};
