import AddIcon from '@mui/icons-material/Add';
import {ButtonProps, IconButton} from '@mui/material';
import React from 'react';
import {FormattedMessage} from 'react-intl';

type Props = {caption?: string} & ButtonProps;

export const AddButton: React.FC<Props> = ({caption, ...props}) => (
    <FormattedMessage id={caption ? caption : 'button.pridat'}>
        {(msg) => (
            <IconButton
                color="primary"
                size="small"
                style={{width: 'fit-content', display: 'flex', alignItems: 'center', padding: '8px 16px', borderRadius: '20px'}}
                {...props}
            >
                <AddIcon />
                &nbsp;&nbsp;
                <p>{msg}</p>
            </IconButton>
        )}
    </FormattedMessage>
);
