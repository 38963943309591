import {CloseOutlined} from '@mui/icons-material';
import {Box, FormHelperTextProps, IconButton, InputAdornment, Tooltip, Typography} from '@mui/material';
import {DatePicker, DateValidationError} from '@mui/x-date-pickers';
import {format} from 'date-fns';
import * as React from 'react';
import {ReactNode, useRef, useState} from 'react';
import {FieldError, Path} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {isNullOrUndefinedOrEmpty} from '../../../utils/CommonUtils';
import {FIELD_WITH_ERROR_FLASH_CLASS} from '../../error/ErrorFocus';
import {CalendarIcon} from '../../icons/CalendarIcon';
import {Span} from '../../styledComponents/Span';
import {TooltipIcon} from '../tooltips/TooltipIcon';

// Defaultní formát datumu
const DEFAULT_FORMAT = 'dd.MM.yyyy';

type Props = {
    readonly name: Path<any>;
    readonly value: string;
    readonly onChange: (...event: any[]) => void;
    readonly onBlur: (...event: any[]) => void;
    readonly label?: string;
    readonly autofocus?: boolean;
    readonly error?: FieldError;
    readonly helperText?: string | null;
    readonly FormHelperTextProps?: Partial<FormHelperTextProps> | undefined;
    readonly disabled?: boolean;
    readonly required: boolean;
    readonly views?: Array<'year' | 'month' | 'day'>;
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly disablePast?: boolean;
    readonly disableFuture?: boolean;
    readonly tooltip?: ReactNode;
};

const renderFormLabel = (label: string | undefined, title: string, showRequired: boolean) => (
    <Typography component="span">
        <Box
            component="span"
            sx={{
                fontWeight: 'bold',
            }}
        >
            {label}
            {showRequired ? <sup>*</sup> : false}
            {typeof title === 'string' && title.length > 0 && (
                <Tooltip title={title} placement="top">
                    <sup>&nbsp;?</sup>
                </Tooltip>
            )}
        </Box>
    </Typography>
);

export const DatePickerField: React.FC<Props> = ({
    onChange,
    onBlur,
    value,
    FormHelperTextProps,
    label,
    disabled,
    helperText,
    views,
    minDate,
    maxDate,
    disablePast,
    disableFuture,
    name,
    required,
    error,
    tooltip,
}) => {
    const divRef = useRef(null);

    const intl = useIntl();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null | undefined>(null);
    const [pickerOpen, setPickerOpen] = useState<boolean>(false);

    const helperTextId = helperText && name ? `${name}-helper-text` : undefined;

    const formattedLabel = label ? intl.formatMessage({id: label}) : undefined;

    const handleOpenPicker = () => {
        setAnchorEl(divRef?.current);
        setPickerOpen(true);
    };

    /**
     * Handler na změnu datumu
     * @param {any} dateVal
     * @param {string|null} currentValue
     */
    const handleOnChange = (dateVal: Date | null, {validationError}: {validationError: DateValidationError}) => {
        if (validationError === 'invalidDate') {
            onBlur('');
            onChange('');
            return;
        }

        const date = dateVal ? format(new Date(dateVal as Date), 'yyyy-MM-dd') : null;
        onChange(date);
    };

    const handleClear = () => onChange(null);

    const onClose = () => {
        setPickerOpen(false);
    };

    const labelText = (
        <Typography component="span">
            <Box
                component="span"
                sx={{
                    fontWeight: 'bold',
                }}
            >
                {renderFormLabel(formattedLabel, '', required)}
            </Box>
        </Typography>
    );

    const dateFormat: string | undefined = !!views && !views.includes('day') ? undefined : DEFAULT_FORMAT;

    return (
        <DatePicker
            ref={divRef}
            views={views || ['year', 'month', 'day']}
            label={labelText}
            value={isNullOrUndefinedOrEmpty(value) ? null : new Date(value)}
            format={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
            disabled={disabled}
            onChange={handleOnChange}
            onClose={onClose}
            open={pickerOpen}
            data-testid={`date-picker-${name}`}
            localeText={{
                clearButtonLabel: 'Smazat',
                todayButtonLabel: 'Dnes',
                toolbarTitle: 'Vyberte datum',
            }}
            slots={{
                inputAdornment: () => (
                    <InputAdornment position="end">
                        <TooltipIcon htmlText={<FormattedMessage id="tooltip.smazat" />} aria-label="delete" placement="top-start" showIcon={false}>
                            <Span>
                                <IconButton
                                    onClick={handleClear}
                                    size="small"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                    }}
                                    disabled={disabled}
                                    data-testid={`${name}-calendar-delete-button`}
                                >
                                    <CloseOutlined
                                        color={error ? 'error' : 'secondary'}
                                        sx={{
                                            opacity: disabled ? 0.2 : 0.6,
                                        }}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Span>
                        </TooltipIcon>

                        <TooltipIcon htmlText={<FormattedMessage id="tooltip.kalendar" />} aria-label="calendar-pick" placement="top-start" showIcon={false}>
                            <Span>
                                <IconButton
                                    onClick={handleOpenPicker}
                                    size="small"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                    }}
                                    disabled={disabled}
                                    data-testid={`${name}-calendar-open-button`}
                                >
                                    <CalendarIcon
                                        color={error ? 'error' : 'secondary'}
                                        sx={{
                                            opacity: disabled ? 0.2 : 0.6,
                                        }}
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Span>
                        </TooltipIcon>

                        {tooltip}
                    </InputAdornment>
                ),
            }}
            slotProps={{
                popper: {
                    anchorEl,
                },
                actionBar: {
                    actions: ['clear', 'today'],
                },
                textField: {
                    datatype: 'date-picker',
                    sx: {marginTop: 1},
                    className: error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined,
                    variant: 'standard',
                    autoComplete: 'date',
                    label: (
                        <Typography component="span">
                            <Box component="span" sx={{fontWeight: 'bold'}}>
                                {labelText}
                            </Box>
                        </Typography>
                    ),
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                    FormHelperTextProps: {id: helperTextId, ...FormHelperTextProps},
                    onDoubleClick: disabled ? undefined : handleOpenPicker,
                },
            }}
        />
    );
};
