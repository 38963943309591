import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';

type Props = SvgIconProps;

export const MailIcon: React.FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path d="M 17.462 2.351 L 6.58 2.351 C 3.147 2.356 0.366 5.26 0.362 8.845 L 0.362 15.337 C 0.366 18.921 3.147 21.826 6.58 21.83 L 17.462 21.83 C 20.894 21.826 23.676 18.921 23.68 15.337 L 23.68 8.845 C 23.676 5.26 20.894 2.356 17.462 2.351 Z M 22.126 15.337 C 22.123 18.025 20.037 20.204 17.462 20.207 L 6.58 20.207 C 4.006 20.204 1.919 18.025 1.916 15.337 L 1.916 8.845 C 1.919 6.156 4.006 3.978 6.58 3.975 L 17.462 3.975 C 18.968 3.976 20.379 4.737 21.252 6.017 L 12.023 11.948 L 4.859 7.303 C 4.353 6.97 3.691 7.332 3.666 7.957 C 3.655 8.251 3.797 8.529 4.039 8.682 L 12.019 13.857 L 21.936 7.484 C 22.061 7.926 22.125 8.383 22.126 8.845 Z" />
    </SvgIcon>
);
