import {SvgIcon, SvgIconProps} from '@mui/material';
import * as React from 'react';

type Props = SvgIconProps;

export const ElectricityIcon: React.FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path d="M 6.15 22.339 C 5.885 22.125 5.776 21.773 5.876 21.443 L 8.531 12.75 L 4.872 12.75 C 4.204 12.75 3.817 12.029 4.055 11.478 C 4.053 11.478 5.752 8.515 9.153 2.589 C 9.527 1.935 10.23 1.529 10.988 1.529 L 16.976 1.529 C 17.524 1.529 18.107 2.06 17.524 2.791 L 14.714 6.438 L 18.336 6.438 C 19.358 6.438 19.629 7.35 19.165 7.955 L 7.336 22.225 C 7.17 22.425 6.929 22.529 6.684 22.529 C 6.494 22.529 6.305 22.467 6.15 22.339 Z M 5.751 11.347 L 10.43 11.347 L 8.043 19.171 L 17.44 7.84 L 11.874 7.84 L 15.623 2.931 L 10.988 2.931 C 10.736 2.931 10.5 3.066 10.377 3.285 L 5.751 11.347 Z" />
    </SvgIcon>
);
