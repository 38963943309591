import {useState} from 'react';

export type UseDialogApi = {
    isOpen: boolean;
    close: () => void;
    open: () => void;
};

/**
 * useDialog - hook for managing opening state of mui Dialogs
 *
 */
export const useDialog = (defaultOpen: boolean = false): UseDialogApi => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const close = () => {
        setIsOpen(false);
    };

    const open = () => {
        setIsOpen(true);
    };

    return {
        close,
        open,
        isOpen,
    };
};
