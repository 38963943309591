import {AddressFormatUtils} from '@common/utils/AddressFormatUtils';
import {Kontakt, NotifikaceKategorie, UcetSkupina} from '@eon.cz/apollo13-graphql-web';
import {Grid, Typography} from '@mui/material';
import {groupBy} from 'lodash';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Div} from '../../../common/components/styledComponents/Div';
import {KontaktChip} from '../chip/KontaktChip';
import {KontaktSideText, NULL_KEY} from './KontaktSideText';

type Props = {
    readonly kontaktyMap: Record<string, Kontakt[]>;
    readonly ucetSkupina: UcetSkupina | null | undefined;
    readonly kategorie: NotifikaceKategorie;
    readonly onDeleteItem: (kontakt: Kontakt) => void;
};

export const KontaktyKategorieWithEanOrEic: React.FC<Props> = ({kontaktyMap, ucetSkupina, kategorie, onDeleteItem}) => {
    const mapOfContactsWithEan = groupBy(
        kontaktyMap[kategorie.id]?.filter((kontakt) => kontakt.ean && kontakt.zvalidovany),
        'ean',
    );

    const mapOfContactsWithEic = groupBy(
        kontaktyMap[kategorie.id]?.filter((kontakt) => kontakt.eic && kontakt.zvalidovany),
        'eic',
    );

    const mapOfContactsWithoutEanOrEic =
        kontaktyMap[kategorie.id]?.filter((kontakt) => kontakt.eic === null && kontakt.ean === null && kontakt.zvalidovany).length > 0
            ? {
                  [NULL_KEY as string]: kontaktyMap[kategorie.id]?.filter((kontakt) => kontakt.eic === null && kontakt.ean === null && kontakt.zvalidovany),
              }
            : {};

    const mappedContactsWithEanOrEic = {...mapOfContactsWithEan, ...mapOfContactsWithEic};
    const mappedContactsWithoutEanOrEic = {...mapOfContactsWithoutEanOrEic};

    if (Object.keys({...mappedContactsWithEanOrEic, ...mappedContactsWithoutEanOrEic}).length === 0) {
        return null;
    }

    return (
        <>
            {Object.keys(mappedContactsWithoutEanOrEic).length > 0 && (
                <Grid>
                    <Typography variant="h6">
                        <FormattedMessage id="kontakty.without.eanOrEic" />
                    </Typography>

                    {Object.keys(mappedContactsWithoutEanOrEic).map((eanOrEic) => {
                        return (
                            <Grid container spacing={0} key={eanOrEic}>
                                <Grid item xs={12} sm={5}>
                                    <KontaktSideText
                                        obchodniPartner={ucetSkupina?.obchodniPartner ?? undefined}
                                        mappedContacts={mappedContactsWithoutEanOrEic}
                                        eanOrEic={eanOrEic}
                                    />

                                    {eanOrEic !== NULL_KEY ? (
                                        <Typography variant="caption">
                                            {AddressFormatUtils.formatAddressLine(
                                                mappedContactsWithoutEanOrEic[eanOrEic][0]?.ean
                                                    ? mappedContactsWithoutEanOrEic[eanOrEic][0]?.eanAdresa
                                                    : mappedContactsWithoutEanOrEic[eanOrEic][0]?.eicAdresa,
                                            )}
                                        </Typography>
                                    ) : (
                                        <Typography variant="caption">{AddressFormatUtils.formatAddressLine(ucetSkupina?.obchodniPartner?.adresa)}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={7}>
                                    {mappedContactsWithoutEanOrEic[eanOrEic].map((kontakt) => (
                                        <Div key={kontakt.id} sx={{padding: 1}}>
                                            <KontaktChip kontakt={kontakt} onDeleteClicked={onDeleteItem} />
                                        </Div>
                                    ))}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {Object.keys(mappedContactsWithEanOrEic).length > 0 && (
                <Grid paddingTop={3}>
                    <Typography variant="h6">
                        <FormattedMessage id="kontakty.with.eanOrEic" />
                    </Typography>

                    {Object.keys(mappedContactsWithEanOrEic).map((eanOrEic) => {
                        return (
                            <Grid container spacing={0} key={eanOrEic}>
                                <Grid item xs={12} sm={5}>
                                    <KontaktSideText
                                        obchodniPartner={ucetSkupina?.obchodniPartner ?? undefined}
                                        mappedContacts={mappedContactsWithEanOrEic}
                                        eanOrEic={eanOrEic}
                                    />

                                    {eanOrEic !== NULL_KEY ? (
                                        <Typography variant="caption">
                                            {AddressFormatUtils.formatAddressLine(
                                                mappedContactsWithEanOrEic[eanOrEic][0]?.ean
                                                    ? mappedContactsWithEanOrEic[eanOrEic][0]?.eanAdresa
                                                    : mappedContactsWithEanOrEic[eanOrEic][0]?.eicAdresa,
                                            )}
                                        </Typography>
                                    ) : (
                                        <Typography variant="caption">{AddressFormatUtils.formatAddressLine(ucetSkupina?.obchodniPartner?.adresa)}</Typography>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={7}>
                                    {mappedContactsWithEanOrEic[eanOrEic].map((kontakt) => (
                                        <Div key={kontakt.id} sx={{padding: 1}}>
                                            <KontaktChip kontakt={kontakt} onDeleteClicked={onDeleteItem} />
                                        </Div>
                                    ))}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            )}
        </>
    );
};
