import {ApolloError} from '@apollo/client';
import {PageLayout} from '@common/components/layout/PageLayout';
import {PredvyplneneUdaje, Query} from '@eon.cz/apollo13-graphql-web';
import {useAppContext} from '@lib/context/AppContext';
import {useDialog} from '@lib/hooks/useDialog';
import {Kontakty} from '@modules/kontakty/Kontakty';
import {AuthenticateDialog} from '@modules/kontakty/dialogs/AuthenticateDialog';
import {GetServerSideProps, NextPage} from 'next';
import {useEffect, useState} from 'react';
import {CommonQueries} from '../client/common/CommonQueries';
import {NotificationType} from '../client/common/components/notifications/NotificationModel';
import {initApollo} from '../client/lib/apolloClient';

type Props = {
    predvyplneneUdaje?: PredvyplneneUdaje;
    error?: ApolloError;
};

const Index: NextPage<Props> = ({predvyplneneUdaje, error}) => {
    const [isCheckingAuthorization, setIsCheckingAuthorization] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const {addNotification} = useAppContext();
    const {isOpen: isAuthenticateDialogOpen, close: closeAuthenticateDialog} = useDialog(!isAuthenticated);

    const toggleAuthentication = (value: boolean) => {
        setIsAuthenticated(value);
    };

    const toggleIsCheckingAuthorization = (isCheckingAuthorization?: boolean) => {
        setIsCheckingAuthorization((prevState) => isCheckingAuthorization ?? !prevState);
    };
    useEffect(() => {
        if (isAuthenticated) {
            setIsCheckingAuthorization(true);

            toggleAuthentication(true);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (error?.message) {
            addNotification({text: error.message, type: NotificationType.ERROR});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error?.message]);

    return (
        <PageLayout logged={isAuthenticated}>
            {isAuthenticated && (
                <Kontakty
                    toggleAuthentication={toggleAuthentication}
                    isCheckingAuthorization={isCheckingAuthorization}
                    toggleIsCheckingAuthorization={toggleIsCheckingAuthorization}
                />
            )}

            {!isAuthenticated && (
                <AuthenticateDialog
                    close={closeAuthenticateDialog}
                    isOpen={isAuthenticateDialogOpen || !isAuthenticated}
                    toggleIsAuthenticated={toggleAuthentication}
                    predvyplneneUdaje={predvyplneneUdaje}
                />
            )}
        </PageLayout>
    );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    const {
        query: {hash, HASH},
    } = ctx;

    const isHash = HASH ?? hash;

    const apolloClient = initApollo(null, `${process.env.BACKEND_KONTAKTY_ENDPOINT}/graphql`);

    if (isHash) {
        try {
            const {data} = await apolloClient.query<Query>({
                query: CommonQueries.gql.predvyplneneUdaje,
                variables: {hash: isHash},
            });

            const predvyplneneUdaje = data?.kontakty?.predvyplneneUdaje;

            return {
                props: {
                    predvyplneneUdaje,
                },
            };
        } catch (error) {
            const apolloError = JSON.parse(JSON.stringify(error));
            return {
                props: {
                    error: apolloError,
                },
            };
        }
    }

    return {
        props: {},
    };
};

export default Index;
