import {Button, CircularProgress, Icon} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {ApolloActionButtonProps} from '@lib/types';

export type ActionButtonProps = ApolloActionButtonProps & {
    /**
     * Caption message id to use for the button
     */
    readonly caption: string;

    /**
     * Icon name
     */
    readonly icon: string | React.ReactNode;
};

/**
 * General action button which has "running" state
 */
export const ActionButton: React.FC<ActionButtonProps> = ({caption, running, disabled, icon, ...props}) => {
    const iconComponent = typeof icon === 'string' ? <Icon>{icon}</Icon> : icon;

    return (
        <FormattedMessage id={caption}>
            {(msg: any) => (
                <Button disabled={disabled || running} {...props}>
                    {running ? <CircularProgress size={20} /> : iconComponent}
                    &nbsp;&nbsp;
                    {msg}
                </Button>
            )}
        </FormattedMessage>
    );
};
