import {Autocomplete} from '@common/components/autocomplete/Autocomplete';
import {Controller, FieldValues, Path, useFormContext} from 'react-hook-form';

type Props<T extends FieldValues> = {
    readonly name: Path<T>;
    readonly defaultValue?: any;
    readonly kontaktyProNaseptavani: string[];
    readonly disabled?: boolean;
};

export const TelephoneAutocomplete = <T extends FieldValues>(props: Props<T>) => {
    const {control} = useFormContext();

    return (
        <Controller
            name={props.name}
            control={control}
            defaultValue={props.defaultValue}
            render={({field: {value, onChange}, fieldState: {error}}) => (
                <Autocomplete
                    onChange={onChange}
                    value={value}
                    error={error}
                    label="ucet.telefon"
                    options={props.kontaktyProNaseptavani}
                    disabled={props?.disabled}
                />
            )}
        />
    );
};
