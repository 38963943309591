import {gql} from '@apollo/client';

export const CREATE_KONTAKT_MUTATION = gql`
    mutation CreateKontakt($input: CreateKontaktInput!) {
        kontakty {
            createKontakt(input: $input) {
                id
            }
        }
    }
`;
