/**
 * CSS class used to mark field with error that is focusable
 */
export const FIELD_WITH_ERROR_FOCUSABLE_CLASS = 'FIELDWITHERRORFOCUSABLE';

/**
 * CSS class used to mark field with error that will only flash
 */
export const FIELD_WITH_ERROR_FLASH_CLASS = 'FIELDWITHERRORFLASH';

const PHASE_1_STYLE = 'background-color: rgba(240, 200, 200, 0.5)';
const PHASE_2_STYLE = 'transition: background-color 0.5s';

/**
 * Set as onSubmitFail to form
 */
export const onSubmitFailFocusFieldWithError = () => {
    // Leave time for re-render
    window.setTimeout(() => {
        // Try to find input with focusable error
        const focusable = document.querySelectorAll(
            `.${FIELD_WITH_ERROR_FOCUSABLE_CLASS} input, .${FIELD_WITH_ERROR_FOCUSABLE_CLASS} textarea:not([aria-hidden=true])`,
        ) as NodeListOf<HTMLElement>;

        if (focusable && focusable.length > 0) {
            (focusable[0] as HTMLElement).focus();
        }

        // Find fields to flash
        const flashing = document.querySelectorAll(`.${FIELD_WITH_ERROR_FLASH_CLASS}`) as NodeListOf<HTMLElement>;

        if (flashing && flashing.length > 0 && !focusable) {
            // When we have not found focusable, try to focus first flashing to get it into view
            (flashing[0] as HTMLElement).focus();
        }

        const toFlash = Array.from(
            document.querySelectorAll(`.${FIELD_WITH_ERROR_FLASH_CLASS}, .${FIELD_WITH_ERROR_FOCUSABLE_CLASS}`) as NodeListOf<HTMLElement>,
        );

        if (toFlash && toFlash.length > 0) {
            // Save old styles and set background color for elements
            const oldStyles: ReadonlyArray<string | null> = [];
            toFlash.forEach((e: HTMLElement) => {
                const oldStyle = e.getAttribute('style');
                oldStyles.concat(oldStyle);
                const newStyle = oldStyle === null ? PHASE_1_STYLE : `${oldStyle}; ${PHASE_1_STYLE}`;
                e.setAttribute('style', newStyle);
            });

            // Ease off
            window.setTimeout(() => {
                toFlash.forEach((e: HTMLElement, i: number) => {
                    const newStyle2 = oldStyles[i] === null ? PHASE_2_STYLE : `${oldStyles[i]}; ${PHASE_2_STYLE}`;
                    e.setAttribute('style', newStyle2);
                });
            }, 300);

            // Return back original value after some time
            window.setTimeout(() => {
                toFlash.forEach((e: HTMLElement, i: number) => {
                    e.setAttribute('style', String(oldStyles[i]));
                });
            }, 2000);
        }
    }, 200);
};
