import {SvgIcon, SvgIconProps} from '@mui/material';
import React from 'react';

type Props = SvgIconProps;

export const PhoneIcon: React.FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path d="M 7.626 2.866 C 8.455 2.864 9.251 3.192 9.838 3.781 L 11.612 5.557 L 8.962 8.275 L 7.993 9.271 L 8.977 10.252 L 13.806 15.08 L 14.814 16.089 L 15.807 15.068 L 18.427 12.373 L 20.316 14.261 C 21.537 15.483 21.537 17.464 20.316 18.685 L 17.99 21.01 C 14.247 20.46 10.795 18.673 8.184 15.935 C 5.441 13.314 3.647 9.857 3.091 6.107 L 5.414 3.781 C 5.999 3.192 6.795 2.864 7.626 2.866 M 7.626 1.459 C 6.423 1.457 5.269 1.935 4.421 2.788 L 1.64 5.566 C 1.64 5.566 1.645 11.381 7.191 16.927 C 12.706 22.443 18.468 22.459 18.53 22.459 L 18.531 22.459 L 21.312 19.678 C 23.083 17.908 23.083 15.037 21.312 13.268 L 18.414 10.371 L 14.799 14.087 L 9.97 9.259 L 13.589 5.542 L 10.831 2.788 C 9.984 1.935 8.83 1.457 7.626 1.459 Z" />
    </SvgIcon>
);
